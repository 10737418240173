import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Card, Text } from 'theme-ui'

const PageCookiePolicy = props => {

  return (
    <Layout {...props}>
      <Seo title='BlobBahane Hakkında' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Hakkımızda'
          subheader='Bir bahane ile buraya geldiniz. Bu sayfayı neden incelemek istersiniz ki? Haydi bloğa geri dönün.'
        />
      </Stack>
      <Stack>
        <Main>

          <Text variant='h4'>
            BlogBahane hakkında
          </Text>
          <Text variant='p'>
            Buralar değerlenecek. Yerinizi Ayırın!
          </Text>


        </Main>
      </Stack>
    </Layout>
  )
}

export default PageCookiePolicy
